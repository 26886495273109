<!--门店统计概况-->
<template>
	<div class="mainBox">
		<!--日期筛选-->
		<div class="handleBox overviewTime flex flex-jc-fe flex-ai-c">
			<el-radio-group v-model="dateRadio" style="margin-right:10px">
				<el-radio label="day">日</el-radio>
				<el-radio label="week">周</el-radio>
				<el-radio label="month">月</el-radio>
				<el-radio label="year">年</el-radio>
			</el-radio-group>

			<el-date-picker v-show="dateRadio == 'year'" v-model="inputyear" size="mini" type="year" value-format="yyyy"
				format="yyyy年" placeholder="选择年" @change="getList()">
			</el-date-picker>
			<el-date-picker v-show="dateRadio == 'month'" v-model="inputmonth" size="mini" type="month"
				value-format="yyyy-MM-dd" format="yyyy年MM月" placeholder="选择月" @change="getList()">
			</el-date-picker>
			<el-date-picker v-show="dateRadio == 'week'" v-model="inputweek" size="mini" type="week"
				value-format="yyyy-MM-dd" format="yyyy 第 WW 周" placeholder="选择周" @change="getList()">
			</el-date-picker>
			<el-date-picker v-show="dateRadio == 'day'" v-model="inputDay" size="mini" type="date"
				value-format="yyyy-MM-dd" format="yyyy年MM月dd日" placeholder="选择天" @change="getList()">
			</el-date-picker>
		</div>

		<div class="handleLogBox">
			<el-row :gutter="10" type="flex" justify="space-between">
				<!-- <el-col :span="12">
					<el-card shadow="never">
						<el-row :gutter="12" type="flex" align="middle">
							<el-col :span="11" style="background: #f9eef3" class="card-icon one flex flex-jc-c flex-ai-c">
								<div style="background: #ff85c0;" class="icon-style flex flex-jc-c flex-ai-c">
									<i class="el-icon-bank-card"></i>
								</div>
							</el-col>
							<el-col :span="13">
								<p class="log_num">{{formData.user_count}}</p>
								<p class="log_text">新增用户数</p>
							</el-col>
						</el-row>
					</el-card>
				</el-col> -->
				<el-col :span="24">
					<el-card shadow="never" @click.native="jumpPage()">
						<el-row :gutter="12" type="flex" align="middle">
							<el-col :span="11" style="background: #f9eef3" class="card-icon one flex flex-jc-c flex-ai-c">
								<div style="background: #ff85c0;" class="icon-style flex flex-jc-c flex-ai-c">
									<i class="el-icon-bank-card"></i>
								</div>
							</el-col>
							<el-col :span="13">
								<p class="log_num">{{formData.vip_count}}</p>
								<p class="log_text">新增会员数</p>
							</el-col>
						</el-row>
					</el-card>
				</el-col>
			</el-row>
		</div>

		<div class="overviewBox">
			<!--用户访问量统计数据-->
			<div class="flex" style="height:calc(100% - 140px);margin-top:15px">
				<div class="overviewHouse" style="width: 53%;margin-right: 1%;">
					<div class="tips flex flex-jc-sb flex-ai-c">
						订单金额统计
						<div style="color: #7B84FF;">订单金额：<b style="margin-right: 8px;">{{formData.amount}}</b>元</div>
					</div>
					<div ref="chart" :style="{width: '100%', height: '90%'}"></div>
				</div>

				<div class="overviewHouse" style="width: 46%;">
					<div class="tips">订单统计</div>
					<div ref="pieChart" id="piechart" :style="{width: '80%', height: '90%',margin:'0 auto'}"></div>
				</div>
			</div>

		</div>

	</div>
</template>
<script>
	var echarts = require('echarts');
	import {
		houseOverviewApi
	} from '@/api/overview.js';
	export default {
		data() {
			return {
				dateRadio: 'day', //年月周-默认周
				selectDate: null,

				inputyear: '',
				inputmonth: '',
				inputweek: '',
				inputDay: '',
				type: '',

				formData: {
					amount: 0
				}
			}
		},
		watch: {
			isCollapse() {
				this.resizeWindow();
			},

			'dateRadio': function() {
				var myDate = new Date();
				switch (this.dateRadio) {
					case 'year':
						this.inputyear = String(myDate.getFullYear());
						break;
					case 'month':
						this.inputmonth = (new Date(myDate.getFullYear(), myDate.getMonth(), 1))
						break;
					case 'week':
						this.inputweek = this.dataFormat(new Date(myDate.getFullYear(), myDate.getMonth(), (myDate
							.getDate() - myDate.getDay()) + 1));
						break;
					case 'day':
						this.inputDay = this.dataFormat(new Date(myDate.getFullYear(), myDate.getMonth(), myDate
							.getDate()));
						break;
					default:
						break;
				}
				this.getList();
			}
		},

		mounted() {
			this.init();
		},

		methods: {
			//初始化数据
			init() {
				let _this = this;
				this.$nextTick(() => {
					if (_this.dateRadio == 'day') {
						_this.inputDay = _this.dataFormat(new Date(new Date().getFullYear(), new Date().getMonth(),
							new Date().getDate()));
						_this.getList();
					}
					//  根据窗口大小调整曲线大小
					window.onresize = () => {
						_this.resizeWindow();
					}
				})
			},

			//窗口改变统计图自适应
			resizeWindow: function() {
				let _this = this;
				if (_this.$refs.chart) {
					let myChart = echarts.init(_this.$refs.chart);
					let pieChart = echarts.init(_this.$refs.pieChart);
					myChart.resize();
					pieChart.resize();
				}
			},

			//获取数据
			getList: function() {
				//筛选日期
				this.selectDate = this.dateRadio == 'day' ? this.inputDay : this.dateRadio == 'week' ? this.inputweek :
					this.dateRadio == 'month' ? this.inputmonth : this.inputyear;
				//筛选类型
				this.type = this.dateRadio == 'day' ? 4 : this.dateRadio == 'week' ? 1 : this.dateRadio == 'month' ?
					2 : 3;

				houseOverviewApi({
					type: this.type,
					date: this.selectDate,
				}).then(response => {
					this.formData = response;

					this.drawLine();
					this.drawPie();
				})
			},

			// 折线图
			drawLine: function() {
				let _this = this;
				setTimeout(function() {
					var myChart = echarts.init(_this.$refs.chart);
					myChart.setOption({
						tooltip: {
							trigger: 'axis',
							axisPointer: {
								label: {
									show: true
								}
							},
						},
						grid: { //位置
							top: '7%',
							left: '1%',
							right: '0%',
							bottom: '0%',
							containLabel: true
						},
						xAxis: {
							type: 'category',
							data: _this.formData.line.xAxis
						},
						yAxis: {
							type: 'value',
							splitLine: {
								show: true,
								lineStyle: {
									type: 'dashed'
								}
							}
						},
						series: [{
							name: '订单金额',
							type: 'line',
							data: _this.formData.line.yAxis,
							label: {
								position: 'inner',
								fontSize: 14,
							},
							itemStyle: {
								normal: {
									color: '#7B84FF', //设置颜色
									label: {
										show: true,
										position: 'top',
										textStyle: {
											color: '#7B84FF'
										}
									}
								}
							}
						}]
					});
				}, 100)
			},

			//饼图
			drawPie: function() {
				var myChart = echarts.init(this.$refs.pieChart);
				myChart.setOption({
					tooltip: {
						trigger: 'item'
					},
					legend: {
						top: '7%',
						left: 'center',
						type: 'scroll',
					},
					series: {
						type: 'pie',
						radius: '50%',
						label: {
							alignTo: 'edge',
							formatter: '{name|{b}}\n{value|{c} 个}',
							minMargin: 5,
							edgeDistance: 10,
							lineHeight: 20,
							rich: {
								value: {
									fontSize: 10,
									color: '#999'
								}
							}
						},
						labelLine: {
							length: 15,
							length2: 0,
							maxSurfaceAngle: 80
						},
						labelLayout: function(params) {
							var isLeft = params.labelRect.x < myChart.getWidth() / 2;
							var points = params.labelLinePoints;
							// Update the end point.
							points[2][0] = isLeft ?
								params.labelRect.x :
								params.labelRect.x + params.labelRect.width;

							return {
								labelLinePoints: points
							};
						},
						data: this.formData.pieChart
					}
				})
			},
			jumpPage: function(){
				this.$router.push('/houseMember')
			},
			//时间转换
			dataFormat: function(time) {
				return `${time.getFullYear()}-${time.getMonth() + 1 >= 10 ? (time.getMonth() + 1) : '0' + (time.getMonth() + 1)}-${time.getDate() >= 10 ? time.getDate() : '0' + time.getDate()}`;
			}
		}
	}
</script>
